<template>
  <div draggable="false" class="no-pictures">
    <div class="no-pictures-container">
      <h1>Gallery is empty</h1>
      <img draggable="false" src="~/lv/no_data/lv_no-content.png" v-if="appCodename == 'lv'">
      <img draggable="false" src="~/lbm/no_data/lbm_no-content.svg" v-if="appCodename == 'lbm'">
      <p>
        Drag and drop an image into your browser window or click the button to add pictures to the gallery
      </p>
      <button class="btn btn-primary" @click="upload">
        <i class="far fa-plus-circle mr-2" />
        Upload
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    appCodename() {
      return Styxie.WLC.appCodename
    }
  },
  methods: {
    upload() {
      this.$parent.$refs.droparea.$refs.file.click()
    }
  }
}
</script>
