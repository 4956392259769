<template>
  <form id="js-public-access-form">
    <div class='form-group row'>
      <div class='col-12 col-sm-3 text-sm-right'>
        <label class='pt-2'>Public Access</label>
      </div>

      <div class='col-12 col-sm-9'>
        <div class='switch-button switch-button-lg switch-button-success'>
          <input type="checkbox" v-model.trim="whiteLabelConfiguration.virtual_public_access">
          <span><label @click="switchPublicAccess" /></span>
        </div>
      </div>
    </div>

    <div class='form-group row'>
      <div class='col-12 col-sm-3 text-sm-right'>
        <label class='pt-2'>Default</label>
      </div>

      <div class='col-12 col-sm-9'>
        <div class='switch-button switch-button-lg switch-button-success'>
          <input type="checkbox" v-model.trim="whiteLabelConfiguration.virtual_default">
          <span><label @click="switchDefault" /></span>
        </div>
      </div>
    </div>

    <fieldset>
      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.public_subdomain }">
            <label>Public subdomain</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.public_subdomain }">
            <div class="input-group">
              <input type="text"
                v-model.trim.lazy="whiteLabelConfiguration.public_subdomain"
                class="text-right form-control text-lowercase"
                placeholder="name">
              <div class="input-group-append">
                <span class="input-group-text">{{ publicDomain }}</span>
              </div>
            </div>
            <span class="error" v-if="errors.public_subdomain">{{ errors.public_subdomain }}</span>
          </div>
        </div>
      </div>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.domain }">
            <label>White Label Domain</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.domain }">
            <input type="text"
              v-model.trim.lazy="whiteLabelConfiguration.domain"
              class="form-control text-lowercase"
              placeholder="yourdomain.com"
              :disabled="whiteLabelConfiguration.domain_checked_at">
            <span class="error" v-if="errors.domain">{{ errors.domain }}</span>
            <div class="form-text text-muted">
              <div>{{ brandname }} can serve public access pages from your own domain.</div>
              <div>
                Input domain (or a subdomain) which you'd like to use and configure its CNAME DNS record to
                <code>{{ cnameDomain }}</code>.
              </div>
              <div>
                When we find that your domain is configured correctly, we will automatically enable it.
                <span v-if="appCodename == 'lv'">
                  Please, checkout
                  <a href='https://help.localviking.com/en/articles/3406491-configuring-your-white-label-domain' target='_blank'>
                    How to configure White Label Settings <i class="fa fa-external-link" />
                  </a>
                  help article to get detailed instruction.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.brand }">
            <label>Brand</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.brand }">
            <input type="text"
              v-model.trim.lazy="whiteLabelConfiguration.brand"
              class="form-control"
              placeholder="Brand">
            <span class="error" v-if="errors.brand">{{ errors.brand }}</span>
          </div>
        </div>
      </div>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.email_part }">
            <label>Email</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.email_part }">
            <div class="input-group">
              <input type="text"
                v-model.trim.lazy="whiteLabelConfiguration.email_part"
                class="text-right form-control text-lowercase"
                placeholder='email'>
              <div class="input-group-append">
                <span class="input-group-text">{{ emailSuffix }}</span>
              </div>
            </div>
            <span class="error" v-if="errors.email_part">{{ errors.email_part }}</span>
          </div>
        </div>
      </div>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.primary_color }">
            <label>Primary Color</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.primary_color }">
            <input type="text"
              v-model.trim.lazy="whiteLabelConfiguration.primary_color"
              class="form-control text-uppercase"
              placeholder='#FFFFFF'>
            <span class="error" v-if="errors.primary_color">{{ errors.primary_color }}</span>
          </div>
        </div>
      </div>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.logo }">
            <label>Logo</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.logo }">
            <div class="custom-file">
              <input type="file"
                ref="logo"
                accept="image/*"
                @change="uploadImage({ e: $event, key: 'logo' })"
                class="custom-file-input">
              <label class="custom-file-label">Choose file</label>
            </div>
            <span class="error" v-if="errors.logo">{{ errors.logo }}</span>
          </div>
          <img :src="whiteLabelConfiguration.logo_url" v-show="whiteLabelConfiguration.logo_url" class="logo-img">
        </div>
      </div>

      <hr>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.app_logo }">
            <label>App Logo</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.app_logo }">
            <div class="custom-file">
              <input type="file"
                accept="image/*"
                @change="uploadImage({ e: $event, key: 'app_logo' })"
                class="custom-file-input">
              <label class="custom-file-label">Choose file</label>
            </div>
            <span class="error" v-if="errors.app_logo">{{ errors.app_logo }}</span>
          </div>
          <img v-if="whiteLabelConfiguration.app_logo" :src="whiteLabelConfiguration.app_logo.url" class="logo-img">
        </div>
      </div>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.app_favicon }">
            <label>App Favicon</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.app_favicon }">
            <div class="custom-file">
              <input type="file"
                accept=".png, .jpg, .jpeg, .ico"
                @change="uploadImage({ e: $event, key: 'app_favicon' })"
                class="custom-file-input">
              <label class="custom-file-label">Choose file</label>
            </div>
            <span class="error" v-if="errors.app_favicon">{{ errors.app_favicon }}</span>
          </div>
          <img v-if="whiteLabelConfiguration.app_favicon" :src="whiteLabelConfiguration.app_favicon.url" class="logo-img">
        </div>
      </div>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.google_app_id }">
            <label>Google App ID</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.google_app_id }">
            <input type="text"
              v-model.trim.lazy="whiteLabelConfiguration.google_app_id"
              class="form-control"
              placeholder="Google App ID">
            <span class="error" v-if="errors.google_app_id">{{ errors.google_app_id }}</span>
          </div>
        </div>
      </div>

      <div class='form-group row'>
        <div class="col-12 col-sm-3 text-sm-right col-form-label">
          <div :class="{ field_with_errors: errors.google_app_secret }">
            <label>Google App Secret</label>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div :class="{ field_with_errors: errors.google_app_secret }">
            <input type="text"
              v-model.trim.lazy="whiteLabelConfiguration.google_app_secret"
              class="form-control"
              placeholder="Google App Secret">
            <span class="error" v-if="errors.google_app_secret">{{ errors.google_app_secret }}</span>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import axios from 'axios'

export default {
  name: 'WhiteLabelConfigurationForm',
  props: {
    url: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    publicDomain: {
      type: String,
      required: true
    },
    emailDomain: {
      type: String,
      required: true
    },
    cnameDomain: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      emailSuffix: `@${this.emailDomain}`,
      errors: {},
      whiteLabelConfiguration: this.data
    }
  },
  watch: {
    'whiteLabelConfiguration.virtual_public_access'() {
      this.update('virtual_public_access')
    },
    'whiteLabelConfiguration.public_subdomain'() {
      this.update('public_subdomain')
    },
    'whiteLabelConfiguration.domain'() {
      this.update('domain', () => {
        this.emailSuffix = `@${this.whiteLabelConfiguration.domain}`
      })
    },
    'whiteLabelConfiguration.brand'() {
      this.update('brand')
    },
    'whiteLabelConfiguration.email_part'() {
      this.update('email_part')
    },
    'whiteLabelConfiguration.primary_color'() {
      this.update('primary_color')
    },
    'whiteLabelConfiguration.google_app_id'() {
      this.update('google_app_id')
    },
    'whiteLabelConfiguration.google_app_secret'() {
      this.update('google_app_secret')
    }
  },
  created() {
    this.brandname = Styxie.WLC.brand
    this.whiteLabelConfiguration = this.data
    const errors = {}
    Object.keys(this.whiteLabelConfiguration).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(this.whiteLabelConfiguration, key)) {
        errors[key] = null
      }
    })
    this.errors = errors
  },
  computed: {
    appCodename() {
      return Styxie.WLC.appCodename
    }
  },
  methods: {
    switchPublicAccess() {
      this.whiteLabelConfiguration.virtual_public_access = !this.whiteLabelConfiguration.virtual_public_access
    },
    switchDefault() {
      this.whiteLabelConfiguration.virtual_default = !this.whiteLabelConfiguration.virtual_default
      this.update('virtual_default')
    },
    uploadImage({ e, key }) {
      const { files } = e.target
      if (!files.length) return
      [this.whiteLabelConfiguration[key]] = files
      this.update(key)
      e.target.value = null
    },
    update(attr, callback) {
      const $form = $('#js-public-access-form')
      const data = new FormData()

      $form.toggleWrapper()
      data.append(`white_label_configuration[${attr}]`, this.whiteLabelConfiguration[attr])
      axios.patch(this.url, data)
        .then((res) => {
          this.whiteLabelConfiguration = res.data.attributes
          this.errors = {}
          if (typeof callback === 'function') {
            callback()
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.whiteLabelConfiguration = error.response.data.attributes
            this.errors = error.response.data.errors
          } else {
            this.errors = {}
          }
        })
        .finally(() => {
          $form.toggleWrapper({}, false)
        })
    }
  }
}
</script>

<style scoped>
  .field_with_errors {
    color: #dc3545;
  }

  .field_with_errors input, .field_with_errors textarea {
    border-color: #dc3545;
    border-width: 1px;
  }

  .field_with_errors .input-group-append {
    border: 1px #dc3545 solid;
    border-left: none;
  }

  .field_with_errors .custom-file label {
    border: 1px #dc3545 solid;
  }

  .field_with_errors .custom-file-label::after {
    height: 100%
  }

  .logo-img {
    margin-top: 20px;
    max-width: 300px
  }
  fieldset:disabled {
    filter: blur(0.5px);
  }
</style>
